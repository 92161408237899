import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import firebase from "gatsby-plugin-firebase"
import GetStarted from "../../components/sections/getstarted"
import { navigate } from "gatsby"
import Confetti from "react-confetti"
import { AuthContext } from "../../context/auth"

import { Section, Container } from "../global"

const Analytics = (props) => {
  const [daysArray, setDaysArray] = useState([])

  useEffect(() => {
    const daysArrayLocal = []
    firebase
      .firestore()
      .collection("days")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          const { createdBy, createdAt } = doc.data()
          const id = doc.id
          console.log("days id", doc.id)

          daysArrayLocal.push({
            id,
            createdBy,
            createdAt,
          })
        })
      })
      .then(() => {
        setDaysArray(daysArrayLocal)
      })
      .catch((error) => {
        console.log("Error getting documents: ", error)
      })
  }, [])

  return (
    <>
      <Section style={{ backgroundColor: "#f8f8f8", paddingBottom: "20px" }}>
        <StyledContainer>
          <Subtitle>analytics yo</Subtitle>
          {daysArray.map((l, i) => (
            <IntentionItem key={i}>
              <div className="intentionWrapper">
                <div className="intentionCopy">
                  <IntentionTitle>{l.createdAt}, {l.createdBy}</IntentionTitle>
                </div>
              </div>
            </IntentionItem>
          ))}
        </StyledContainer>
      </Section>
      <GetStarted />
    </>
  )
}

export default Analytics

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 870px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const IntentionItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0px;
`

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const IntentionTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin: 0px;
  margin-top: 10px;
  padding: 0px;
  float: left;
`

const FeatureText = styled.p`
  text-align: center;
`
const IntentionText = styled.p`
  text-align: left;
  float: left;
  margin: 0px;
  padding: 0px;
  margin-top: 5px;
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`
const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
