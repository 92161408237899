import React from "react"
import styled from "styled-components"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import { Container } from "../components/global"
import Analytics from "../components/sections/analytics"

import Footer from "../components/sections/footer"

const AnalyticsPage = () => (
  <Layout>
    <SEO title="Home" />
    <Navigation />
    <HeaderWrapper id="top">
      <Container style={{ paddingLeft: "36px", paddingRight: "36px" }}>
        <Analytics />
      </Container>
    </HeaderWrapper>
    <Footer />
  </Layout>
)

export default AnalyticsPage

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    padding: 100px 0 80px 0;
  }
`

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0;
    font-size: 32px;
    line-height: 34px;
    color: ${(props) => props.theme.color.primary};

    @media (max-width: ${(props) => props.theme.screen.sm}) {
      padding-top: 24px;
      font-size: 22px;
    }
  }

  h2 {
    margin-bottom: 24px;
    margin-top: 24px;
    ${(props) => props.theme.font_size.regular}
    @media (max-width: ${(props) => props.theme.screen.sm}) {
      font-size: 1.0625rem;
      text-align: left;
    }
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: flex;
    flex-direction: column-reverse;
  }
`
